export const useCountries = () => {
    // Son los códigos de los países que se van a mostrar en el mapa (Latinoamérica, USA y España)
  const latamCountries = [
    "ARG", // Argentina
    "BOL", // Bolivia
    "BRA", // Brazil
    "CHL", // Chile
    "COL", // Colombia
    "CRI", // Costa Rica
    "CUB", // Cuba
    "DOM", // Dominican Republic
    "ECU", // Ecuador
    "SLV", // El Salvador
    "GTM", // Guatemala
    "HND", // Honduras
    "MEX", // Mexico
    "NIC", // Nicaragua
    "PAN", // Panama
    "PRY", // Paraguay
    "PER", // Peru
    "URY", // Uruguay
    "VEN", // Venezuela
    "PRI", // Puerto Rico
    "BLZ", // Belize
    "GUY", // Guyana
    "SUR", // Suriname
    "GUF", // French Guiana
    "ATG", // Antigua and Barbuda
    "BHS", // Bahamas
    "BRB", // Barbados
    "GRD", // Grenada
    "JAM", // Jamaica
    "KNA", // Saint Kitts and Nevis
    "LCA", // Saint Lucia
    "VCT", // Saint Vincent and the Grenadines
    "TTO", // Trinidad and Tobago
    "DMA", // Dominica
    "HTI", // Haiti
    "TTO", // Trinidad and Tobago
  ];

  // Only include USA
  const usaCountry = ["USA"];

  // Only include spain
  const europeCountries = ["ESP"];

  //Middle East and North Africa
  const menaCountries = [
    "TUR", // Turkey
    "ESH", // Western Sahara
    "MRT", // Mauritania
    "SOM", // Somalia
    "DZA", // Algeria
    "BHR", // Bahrain
    "EGY", // Egypt
    "IRN", // Iran
    "IRQ", // Iraq
    "ISR", // Israel
    "JOR", // Jordan
    "KWT", // Kuwait
    "LBN", // Lebanon
    "LBY", // Libya
    "MAR", // Morocco
    "OMN", // Oman
    "PSE", // Palestine
    "QAT", // Qatar
    "SAU", // Saudi Arabia
    "SYR", // Syria
    "TUN", // Tunisia
    "ARE", // United Arab Emirates
    "YEM", // Yemen
    "SDN", // Sudan
    "CYP", // Cyprus (Cyprus is in Asia, but it is often grouped with Europe)
  ];

  // Todos los países que se van a mostrar en el mapa
  const allCountries = [
    ...latamCountries,
    ...usaCountry,
    ...europeCountries,
    ...menaCountries,
  ];

  return { allCountries };
}