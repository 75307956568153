import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import features from "./utils/features.json";
import { Tooltip } from "react-tooltip";
import { useCountries } from "./hooks/useCountries";
import { MarkerSvg } from "./icons/MarkerSvg";

export const SimpleMap = () => {
  const { allCountries } = useCountries();

  // Coordenadas de ejemplo
  const markers = [
    {
      name: "USA Headquarters",
      coordinates: [-80.19673805965432, 25.761794193101892],
      country: "USA",
    },
    {
      name: "Brazil",
      coordinates: [-47.9292, -15.7801],
      country: "BRA",
      text: "Lines of Business: Cyber and Property",
    },
    {
      name: "Mexico",
      coordinates: [-99.1332, 19.4326],
      country: "MEX",
      text: "Anillo Periférico 3720 Jardines del Pedregal Álvaro Obregón, 01900",
    },
  ];

  return (
    <div>
      <ComposableMap>
        {/* Dibujar el mapa */}
        <Geographies geography={features}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isInMap = allCountries.includes(geo.id);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: isInMap ? "#7B61FF" : "#121d3b",
                      stroke: "#9bd7fd",
                      strokeWidth: 0.2,
                      outline: "none",
                      pointerEvents: "none",
                    },
                  }}
                />
              );
            })
          }
        </Geographies>

        {/* Agregar los marcadores */}
        {markers.map(({ name, text, coordinates }, index) => (
          <Marker key={index} coordinates={coordinates}>
            <g transform="translate(-6, -16)">
              <MarkerSvg
                style={{ cursor: "pointer" }}
                width={20}
                height={20}
                data-tooltip-id="map-tooltip"
                data-tooltip-html={`
                  ${name}${text ? "<br/><br/>" : ""}
                  ${text ? text : ""}
                `}
              />
            </g>
          </Marker>
        ))}
      </ComposableMap>

      <Tooltip
        id="map-tooltip"
        place="bottom-start"
        style={{
          backgroundColor: "#FFFFFF",
          color: "black",
          border: "none",
          maxWidth: "484px",
          borderRadius: "5px",
          padding: "10px",
          fontSize: "17px",
        }}
      />
    </div>
  );
};
